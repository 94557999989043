.home-shadow-none {
  box-shadow: none;
}

.home-shadow-sm {
  box-shadow: 2px 4px 4.3px -1px rgba(124, 124, 124, 0.25);
}

.home-shadow-md {
  box-shadow: 0px 4px 4px 0px rgba(124, 124, 124, 0.25);
}

.home-shadow-lg {
  box-shadow: 4px 8px 8px 2px rgba(156, 156, 156, 0.25);
}

@property --gradient-stop {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 0%;
}

@property --gradient-angle {
  syntax: '<angle>';
  inherits: false;
  initial-value: 0deg;
}
@keyframes glow-spin {
  0% {
    --gradient-angle: 0deg;
  }
  to {
    --gradient-angle: 360deg;
  }
}
.product-background-image {
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(#282c3114, #282c3114),
    conic-gradient(
      from -90deg at 50% 50%,
      transparent 0deg,
      #7165ff 0deg,
      #7165ff var(--gradient-angle),
      transparent var(--gradient-angle)
    ),
    conic-gradient(
      from -90deg at 50% 50%,
      #7165ff 0deg,
      transparent 0deg,
      transparent calc((360deg - var(--gradient-angle))),
      #7165ff calc((360deg - var(--gradient-angle)))
    );
}

.mask-\[linear-gradient\(0deg\,rgba\(0\,0\,0\,0\.1\)_0\%\,black_20\%\,black_80\%\,rgba\(0\,0\,0\,0\.1\)_100\%\)\] {
  --kit-mask-from-opacity: 1;
  --kit-mask-to-opacity: 0;
  --kit-mask-gradient-from-position: 0%;
  --kit-mask-gradient-to-position: 100%;
  --kit-mask-from: rgba(0, 0, 0, var(--kit-mask-from-opacity))
    var(--kit-mask-gradient-from-position, '');
  --kit-mask-to: rgba(0, 0, 0, var(--kit-mask-to-opacity))
    var(--kit-mask-gradient-to-position, '');
  --kit-mask-stops: var(--kit-mask-from), var(--kit-mask-to);
  --kit-mask-reach: closest-side;
  --kit-mask-at: center;
  -webkit-mask-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.1) 0%,
    black 20%,
    black 80%,
    rgba(0, 0, 0, 0.1) 100%
  );
  mask-image: linear-gradient(0deg, #0000001a, #000 20% 80%, #0000001a);
}
.mask-\[linear-gradient\(0deg\,transparent_0\%\,\#000_50\%\,transparent_100\%\)\] {
  --kit-mask-from-opacity: 1;
  --kit-mask-to-opacity: 0;
  --kit-mask-gradient-from-position: 0%;
  --kit-mask-gradient-to-position: 100%;
  --kit-mask-from: rgba(0, 0, 0, var(--kit-mask-from-opacity))
    var(--kit-mask-gradient-from-position, '');
  --kit-mask-to: rgba(0, 0, 0, var(--kit-mask-to-opacity))
    var(--kit-mask-gradient-to-position, '');
  --kit-mask-stops: var(--kit-mask-from), var(--kit-mask-to);
  --kit-mask-reach: closest-side;
  --kit-mask-at: center;
  -webkit-mask-image: linear-gradient(
    0deg,
    transparent 0%,
    #000 50%,
    transparent 100%
  );
  mask-image: linear-gradient(0deg, transparent 0%, #000 50%, transparent 100%);
}
.mask-\[linear-gradient\(90deg\,rgba\(0\,0\,0\,0\.1\)_0\%\,black_20\%\,black_80\%\,rgba\(0\,0\,0\,0\.1\)_100\%\)\] {
  --kit-mask-from-opacity: 1;
  --kit-mask-to-opacity: 0;
  --kit-mask-gradient-from-position: 0%;
  --kit-mask-gradient-to-position: 100%;
  --kit-mask-from: rgba(0, 0, 0, var(--kit-mask-from-opacity))
    var(--kit-mask-gradient-from-position, '');
  --kit-mask-to: rgba(0, 0, 0, var(--kit-mask-to-opacity))
    var(--kit-mask-gradient-to-position, '');
  --kit-mask-stops: var(--kit-mask-from), var(--kit-mask-to);
  --kit-mask-reach: closest-side;
  --kit-mask-at: center;
  -webkit-mask-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.1) 0%,
    black 20%,
    black 80%,
    rgba(0, 0, 0, 0.1) 100%
  );
  mask-image: linear-gradient(90deg, #0000001a, #000 20% 80%, #0000001a);
}
.mask-\[linear-gradient\(90deg\,transparent_0\%\,\#000_10\%\,\#000_90\%\,transparent_100\%\)\] {
  --kit-mask-from-opacity: 1;
  --kit-mask-to-opacity: 0;
  --kit-mask-gradient-from-position: 0%;
  --kit-mask-gradient-to-position: 100%;
  --kit-mask-from: rgba(0, 0, 0, var(--kit-mask-from-opacity))
    var(--kit-mask-gradient-from-position, '');
  --kit-mask-to: rgba(0, 0, 0, var(--kit-mask-to-opacity))
    var(--kit-mask-gradient-to-position, '');
  --kit-mask-stops: var(--kit-mask-from), var(--kit-mask-to);
  --kit-mask-reach: closest-side;
  --kit-mask-at: center;
  -webkit-mask-image: linear-gradient(
    90deg,
    transparent 0%,
    #000 10%,
    #000 90%,
    transparent 100%
  );
  mask-image: linear-gradient(
    90deg,
    transparent 0%,
    #000 10%,
    #000 90%,
    transparent 100%
  );
}
.mask-\[linear-gradient\(90deg\,transparent_0\%\,\#000_50\%\,transparent_100\%\)\] {
  --kit-mask-from-opacity: 1;
  --kit-mask-to-opacity: 0;
  --kit-mask-gradient-from-position: 0%;
  --kit-mask-gradient-to-position: 100%;
  --kit-mask-from: rgba(0, 0, 0, var(--kit-mask-from-opacity))
    var(--kit-mask-gradient-from-position, '');
  --kit-mask-to: rgba(0, 0, 0, var(--kit-mask-to-opacity))
    var(--kit-mask-gradient-to-position, '');
  --kit-mask-stops: var(--kit-mask-from), var(--kit-mask-to);
  --kit-mask-reach: closest-side;
  --kit-mask-at: center;
  -webkit-mask-image: linear-gradient(
    90deg,
    transparent 0%,
    #000 50%,
    transparent 100%
  );
  mask-image: linear-gradient(
    90deg,
    transparent 0%,
    #000 50%,
    transparent 100%
  );
}
.mask-\[linear-gradient\(90deg\,transparent_0\%\,black_20\%\,black_80\%\,transparent_100\%\)\] {
  --kit-mask-from-opacity: 1;
  --kit-mask-to-opacity: 0;
  --kit-mask-gradient-from-position: 0%;
  --kit-mask-gradient-to-position: 100%;
  --kit-mask-from: rgba(0, 0, 0, var(--kit-mask-from-opacity))
    var(--kit-mask-gradient-from-position, '');
  --kit-mask-to: rgba(0, 0, 0, var(--kit-mask-to-opacity))
    var(--kit-mask-gradient-to-position, '');
  --kit-mask-stops: var(--kit-mask-from), var(--kit-mask-to);
  --kit-mask-reach: closest-side;
  --kit-mask-at: center;
  -webkit-mask-image: linear-gradient(
    90deg,
    transparent 0%,
    black 20%,
    black 80%,
    transparent 100%
  );
  mask-image: linear-gradient(
    90deg,
    transparent 0%,
    black 20%,
    black 80%,
    transparent 100%
  );
}
.mask-none {
  mask-image: none;
}
.mask-circle {
  --kit-mask-from-opacity: 1;
  --kit-mask-to-opacity: 0;
  --kit-mask-gradient-from-position: 0%;
  --kit-mask-gradient-to-position: 100%;
  --kit-mask-from: rgba(0, 0, 0, var(--kit-mask-from-opacity))
    var(--kit-mask-gradient-from-position, '');
  --kit-mask-to: rgba(0, 0, 0, var(--kit-mask-to-opacity))
    var(--kit-mask-gradient-to-position, '');
  --kit-mask-stops: var(--kit-mask-from), var(--kit-mask-to);
  --kit-mask-reach: closest-side;
  --kit-mask-at: center;
  -webkit-mask-image: radial-gradient(
    circle var(--kit-mask-reach) at var(--kit-mask-at),
    var(--kit-mask-stops)
  );
  mask-image: radial-gradient(
    circle var(--kit-mask-reach) at var(--kit-mask-at),
    var(--kit-mask-stops)
  );
}
.mask-ellipse {
  --kit-mask-from-opacity: 1;
  --kit-mask-to-opacity: 0;
  --kit-mask-gradient-from-position: 0%;
  --kit-mask-gradient-to-position: 100%;
  --kit-mask-from: rgba(0, 0, 0, var(--kit-mask-from-opacity))
    var(--kit-mask-gradient-from-position, '');
  --kit-mask-to: rgba(0, 0, 0, var(--kit-mask-to-opacity))
    var(--kit-mask-gradient-to-position, '');
  --kit-mask-stops: var(--kit-mask-from), var(--kit-mask-to);
  --kit-mask-reach: closest-side;
  --kit-mask-at: center;
  -webkit-mask-image: radial-gradient(
    ellipse var(--kit-mask-reach) at var(--kit-mask-at),
    var(--kit-mask-stops)
  );
  mask-image: radial-gradient(
    ellipse var(--kit-mask-reach) at var(--kit-mask-at),
    var(--kit-mask-stops)
  );
}
.mask-to-b {
  --kit-mask-from-opacity: 1;
  --kit-mask-to-opacity: 0;
  --kit-mask-gradient-from-position: 0%;
  --kit-mask-gradient-to-position: 100%;
  --kit-mask-from: rgba(0, 0, 0, var(--kit-mask-from-opacity))
    var(--kit-mask-gradient-from-position, '');
  --kit-mask-to: rgba(0, 0, 0, var(--kit-mask-to-opacity))
    var(--kit-mask-gradient-to-position, '');
  --kit-mask-stops: var(--kit-mask-from), var(--kit-mask-to);
  --kit-mask-reach: closest-side;
  --kit-mask-at: center;
  -webkit-mask-image: linear-gradient(to bottom, var(--kit-mask-stops));
  mask-image: linear-gradient(to bottom, var(--kit-mask-stops));
}
.mask-to-l {
  --kit-mask-from-opacity: 1;
  --kit-mask-to-opacity: 0;
  --kit-mask-gradient-from-position: 0%;
  --kit-mask-gradient-to-position: 100%;
  --kit-mask-from: rgba(0, 0, 0, var(--kit-mask-from-opacity))
    var(--kit-mask-gradient-from-position, '');
  --kit-mask-to: rgba(0, 0, 0, var(--kit-mask-to-opacity))
    var(--kit-mask-gradient-to-position, '');
  --kit-mask-stops: var(--kit-mask-from), var(--kit-mask-to);
  --kit-mask-reach: closest-side;
  --kit-mask-at: center;
  -webkit-mask-image: linear-gradient(to left, var(--kit-mask-stops));
  mask-image: linear-gradient(to left, var(--kit-mask-stops));
}
.mask-to-r {
  --kit-mask-from-opacity: 1;
  --kit-mask-to-opacity: 0;
  --kit-mask-gradient-from-position: 0%;
  --kit-mask-gradient-to-position: 100%;
  --kit-mask-from: rgba(0, 0, 0, var(--kit-mask-from-opacity))
    var(--kit-mask-gradient-from-position, '');
  --kit-mask-to: rgba(0, 0, 0, var(--kit-mask-to-opacity))
    var(--kit-mask-gradient-to-position, '');
  --kit-mask-stops: var(--kit-mask-from), var(--kit-mask-to);
  --kit-mask-reach: closest-side;
  --kit-mask-at: center;
  -webkit-mask-image: linear-gradient(to right, var(--kit-mask-stops));
  mask-image: linear-gradient(to right, var(--kit-mask-stops));
}
.mask-to-t {
  --kit-mask-from-opacity: 1;
  --kit-mask-to-opacity: 0;
  --kit-mask-gradient-from-position: 0%;
  --kit-mask-gradient-to-position: 100%;
  --kit-mask-from: rgba(0, 0, 0, var(--kit-mask-from-opacity))
    var(--kit-mask-gradient-from-position, '');
  --kit-mask-to: rgba(0, 0, 0, var(--kit-mask-to-opacity))
    var(--kit-mask-gradient-to-position, '');
  --kit-mask-stops: var(--kit-mask-from), var(--kit-mask-to);
  --kit-mask-reach: closest-side;
  --kit-mask-at: center;
  -webkit-mask-image: linear-gradient(to top, var(--kit-mask-stops));
  mask-image: linear-gradient(to top, var(--kit-mask-stops));
}
.reach-closest-corner {
  --kit-mask-reach: closest-corner;
}
.reach-closest-side {
  --kit-mask-reach: closest-side;
}
.mask-from-60\% {
  --kit-mask-gradient-from-position: 60%;
}
@media (min-width: 640px) {
  .sm\:mask-ellipse {
    --kit-mask-from-opacity: 1;
    --kit-mask-to-opacity: 0;
    --kit-mask-gradient-from-position: 0%;
    --kit-mask-gradient-to-position: 100%;
    --kit-mask-from: rgba(0, 0, 0, var(--kit-mask-from-opacity))
      var(--kit-mask-gradient-from-position, '');
    --kit-mask-to: rgba(0, 0, 0, var(--kit-mask-to-opacity))
      var(--kit-mask-gradient-to-position, '');
    --kit-mask-stops: var(--kit-mask-from), var(--kit-mask-to);
    --kit-mask-reach: closest-side;
    --kit-mask-at: center;
    -webkit-mask-image: radial-gradient(
      ellipse var(--kit-mask-reach) at var(--kit-mask-at),
      var(--kit-mask-stops)
    );
    mask-image: radial-gradient(
      ellipse var(--kit-mask-reach) at var(--kit-mask-at),
      var(--kit-mask-stops)
    );
  }
  .sm\:mask-from-60\% {
    --kit-mask-gradient-from-position: 60% !important;
  }
  .sm\:reach-closest-corner {
    --kit-mask-reach: closest-corner;
  }
}
.mask-from-20\% {
  --kit-mask-gradient-from-position: 20%;
}
.mask-from-opacity-0 {
  --kit-mask-from-opacity: 0;
}
.mask-via-opacity-100 {
  --kit-mask-gradient-via-position: ;
  --kit-mask-stops: var(--kit-mask-from),
    rgba(0, 0, 0, 1) var(--kit-mask-gradient-via-position), var(--kit-mask-to);
}

@media (min-width: 1024px) {
  .lg\:mask-ellipse {
    --kit-mask-from-opacity: 1;
    --kit-mask-to-opacity: 0;
    --kit-mask-gradient-from-position: 0%;
    --kit-mask-gradient-to-position: 100%;
    --kit-mask-from: rgba(0, 0, 0, var(--kit-mask-from-opacity))
      var(--kit-mask-gradient-from-position, '');
    --kit-mask-to: rgba(0, 0, 0, var(--kit-mask-to-opacity))
      var(--kit-mask-gradient-to-position, '');
    --kit-mask-stops: var(--kit-mask-from), var(--kit-mask-to);
    --kit-mask-reach: closest-side;
    --kit-mask-at: center;
    -webkit-mask-image: radial-gradient(
      ellipse var(--kit-mask-reach) at var(--kit-mask-at),
      var(--kit-mask-stops)
    );
    mask-image: radial-gradient(
      ellipse var(--kit-mask-reach) at var(--kit-mask-at),
      var(--kit-mask-stops)
    );
  }
  .lg\:reach-closest-corner {
    --kit-mask-reach: closest-corner;
  }
  .lg\:mask-from-60\% {
    --kit-mask-gradient-from-position: 60%;
  }
}
.bg-\[radial-gradient\(circle_closest-side\,theme\(\'colors\.primary\/0\.1\'\)_0\%\,theme\(\'colors\.primary\/0\'\)_90\%\)\] {
  background-image: radial-gradient(
    circle closest-side,
    #7165ff1a,
    #7165ff00 90%
  );
}
